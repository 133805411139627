@font-face {
    font-family: segment;
    src: url("../public/fonts/7SEGMENTALDIGITALDISPLAY.ttf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MenuItem {
	text-decoration: none; color: inherit;
}

.Content {
	margin-top: 20px;
}

.ConverterZifferblatt {
	font-family: segment !important; letter-spacing: 4px !important;
}
#ConverterZifferblattTo:disabled {
	color: #000; -webkit-text-fill-color: #000;
}
#ConverterZifferblattTo-label {
	color: rgba(0, 0, 0, 0.6);
}

.Mui-error {
	letter-spacing: normal !important;
}
